<template>
  <div class="CooperateUs">
    <!-- 头部 -->
    <div style="position: fixed; left: 0; right: 0; top: 0; z-index: 100">
      <div class="topbar d-flex align-center">
        <div class="icon" @click="$router.go(-1)">
          <v-icon size="30" color="#000">mdi-arrow-left</v-icon>
        </div>
        <div class="head-title">問題反饋</div>
        <div><v-icon size="30" color="#000">mdi-arrow-left</v-icon></div>
      </div>
    </div>
    <!-- 问题类型 -->
    <div class="question-type">
      <h3>問題類型</h3>
      <div>
        <ul>
          <li
            v-for="(item, index) in typeList"
            :key="index"
            :class="[active == index ? 'active' : '']"
            @click="chioceType(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <!--问题描述 -->
    <div class="question-text">
      <h3>問題描述</h3>
      <div>
        <v-textarea
          auto-grow
          row-height="18"
          v-model="textarea"
          rounded-xl
          solo
          type="text"
          name="input-7-4"
          label="請描述問題詳情"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textarea: "",
      active: null,
      // 问题类型
      typeList: ["帳號問題", "充值問題", "遊戲問題", "其他"],
    };
  },
  methods: {
    chioceType(item, idx) {
      this.active = idx;
    },
  },
};
</script>

<style lang="scss" scoped>
.CooperateUs {
  padding-top: 46px;
  .topbar {
    background-color: #fff;
    padding: 0 24px;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    .head-title {
      text-align: left;
      font-size: 18px;
      color: #000;
    }
  }
  h3 {
    padding: 20px 0px;
    font-size: 18px;
    text-align: left;
  }
  //   问题类型
  .question-type {
    padding: 0 24px;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0;
      li {
        width: 30%;
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid #c1c1c1;
        border-radius: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
      }
    }
    .active {
      border: 2px solid #f85489;
      color: #f85489;
    }
  }
  //   问题描述
  .question-text {
    padding: 0 24px;
    .v-input {
      border-radius: 20px;
    }
  }
}
</style>